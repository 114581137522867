export const CRIPTOREMESA= {
    INSTAGRAM: 'https://www.instagram.com/bithonor/',
    FACEBOOK: 'https://www.facebook.com/bithonor',
    LINKEDIN: 'https://www.linkedin.com/company/bithonor/about/',
    TIKTOK: 'https://www.tiktok.com/@bithonor',
    WHATSAPP: 'https://api.whatsapp.com/message/5ZEAXANG4EKXL1?autoload=1&app_absent=0'
}

export const WHATSAPP_PHONES= [
    {
        image: 'ar.png',
        country: 'Argentina',
        phone: '+56 9 7495 9942',
        nonFormattedPhone: '56974959942',
        isoCode: 'AR'
    },
    {
        image: 'br.png',
        country: 'Brasil',
        phone: '+56 9 7495 9942',
        nonFormattedPhone: '56974959942',
        isoCode: 'BR'
    },
    {
        image: 'cl.png',
        country: 'Chile',
        phone: '+56 9 7495 9942',
        nonFormattedPhone: '56974959942',
        isoCode: 'CL'
    },
    {
        image: 'co.png',
        country: 'Colombia',
        phone: '+56 9 7495 9942',
        nonFormattedPhone: '56974959942',
        isoCode: 'CO'
    },
    {
        image: 'pe.png',
        country: 'Perú',
        phone: '+56 9 7495 9942',
        nonFormattedPhone: '56974959942',
        isoCode: 'PE'
    },
    {
        image: 'do.png',
        country: 'República Dominicana',
        phone: '+56 9 7495 9942',
        nonFormattedPhone: '56974959942',
        isoCode: 'DO'
    },
    // {
    //     image: 'eu.png',
    //     country: 'Europa',
    //     phone: '+34 610 92 99 32'
    // },
]

export const EUROPE_PHONE = {
    image: 'eu.png',
    country: 'Europa',
    phone: '+34 610 92 99 32',
    nonFormattedPhone: '34610929932',
    isoCode: 'EU'
}

export const SOCIAL_MEDIA = [
    {
        image: 'instagram.png',
        title: 'Instagram',
        account: '@bithonor',
        link: CRIPTOREMESA.INSTAGRAM
    },
    {
        image: 'facebook.png',
        title: 'Facebook',
        account: '@bithonor',
        link: CRIPTOREMESA.FACEBOOK
    },
    {
        image: 'linkedin.png',
        title: 'Linkedin',
        account: '@bithonor',
        link: CRIPTOREMESA.LINKEDIN
    },
    {
        image: 'tiktok.png',
        title: 'Tiktok',
        account: '@bithonor',
        link: CRIPTOREMESA.TIKTOK
    }
]