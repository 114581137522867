
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { CRIPTOREMESA } from '@/constants/criptoremesa.constants';
/* STORE */
import { auth } from '@/store/namespaces';
import AuthMethods from '@/store/auth/methods/auth.methods';
/* INTERFACES */
import SvgIcon from '@/components/utilities/SvgIcon.vue';

@Component({
    components: {
        SvgIcon
    },
})
export default class Footer extends Vue {

    criptoRemesa = CRIPTOREMESA;

    get loginDisabled(){
      return process.env.VUE_APP_LOGIN_DISABLED
    }

    /* AUTH */
    @auth.Getter(AuthMethods.getters.GET_AUTHENTICATION)
    isAuthenticated!: boolean;
}
