
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { WHATSAPP_PHONES, EUROPE_PHONE } from '@/constants/criptoremesa.constants';

@Component({
    components: {

    }
})
export default class ContactWhatsappModal extends Vue {
    @Prop() showModal!: boolean;
    show: boolean = false;

    /* MODAL */
    @Watch('showModal')
     renderModal(){
        this.show = this.showModal;
    }

    @Watch('show')
    sendToParent(newVal: boolean){
        this.$emit('changeModalStatus', newVal)
    }
    /* END MODAL */

    get isEuropeVersion(){
        return process.env.VUE_APP_EUROPE_VERSION
    }

    get phones(){
        if (!this.isEuropeVersion) return WHATSAPP_PHONES
        else {
            let europe = []
            europe.push(EUROPE_PHONE)
            return europe
        }
    }

}
    
